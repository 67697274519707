import {useMutation} from '@tanstack/react-query'
import {mfaClient} from 'src/api/apiClient'
import {EnableMfaCommand} from 'src/api/generatedClient'
import {queryClient} from 'src/libs/react-query'

const verifyMfa = async (code: string) => {
  const request = {code} as EnableMfaCommand
  const response = await mfaClient.enableMfa(request)
  return response
}

export const useVerifyMfa = () =>
  useMutation({
    mutationFn: verifyMfa,
    onSuccess: () => queryClient.invalidateQueries({queryKey: ['user']}),
  })
