import {useQuery} from '@tanstack/react-query'
import {mfaClient} from 'src/api/apiClient'

const getQrCode = async () => {
  const response = await mfaClient.getMfaQrCode()
  return response
}

export const useMfaQrCode = (isEnabled: boolean) =>
  useQuery({
    queryFn: () => getQrCode(),
    queryKey: ['qrCode'],
    enabled: isEnabled,
  })
