import {Avatar} from 'primereact/avatar'
import {useUser} from '../../../../../queries/getUser'
import {Skeleton} from 'primereact/skeleton'
import {Button} from 'primereact/button'
import {useNavigate, useParams} from 'react-router-dom'
import {useDeleteUser} from '../../../../../queries/deleteUser'
import {InputSwitch} from 'primereact/inputswitch'
import {useEnableMfa} from '../api/enableMfa'

export const UserDetails = () => {
  const {userId} = useParams()
  const user = useUser(userId ?? '')
  const navigate = useNavigate()
  const deleteUser = useDeleteUser()
  const toggleMfa = useEnableMfa()
  const userLabel = `${user.data?.firstName?.charAt(0).toUpperCase()}${user.data?.lastName
    ?.charAt(0)
    .toUpperCase()}`

  const handleRoleClick = () => {
    navigate('manageRoles')
  }

  const handleMfaClick = () => {
    toggleMfa.mutate(user.data?.userName ?? '')
  }

  const handleResetPasswordClick = () => {
    navigate('resetPassword')
  }

  const handleDeleteClick = () => {
    deleteUser.mutate(user.data?.id ?? '', {
      onSuccess: () => {
        navigate('../')
      },
    })
  }

  const handleEditClick = () => {
    navigate('edit')
  }
  return (
    <div style={{padding: '2rem'}}>
      {user.isLoading ? (
        <>
          <Skeleton shape='circle' size='5rem'></Skeleton>
          <Skeleton className='mb-2'></Skeleton>
          <Skeleton width='10rem' className='mb-2'></Skeleton>
          <Skeleton width='5rem' className='mb-2'></Skeleton>
          <Skeleton height='2rem' className='mb-2'></Skeleton>
          <Skeleton width='10rem' height='4rem'></Skeleton>
        </>
      ) : (
        <div>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div style={{display: 'flex'}}>
              <div style={{margin: '4px 20px 0px 0px'}}>
                <Avatar label={userLabel} shape='circle' size='xlarge' />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                }}
              >
                <h3 style={{paddingLeft: '16.250px'}}>{user.data?.firstName}</h3>

                <Button
                  text
                  icon='pi pi-key'
                  label='Reset Password'
                  onClick={handleResetPasswordClick}
                />
              </div>
            </div>
            <div style={{display: 'flex'}}>
              <Button
                icon='pi pi-trash'
                text
                severity='danger'
                size='large'
                onClick={handleDeleteClick}
              />
              <Button
                icon='pi pi-pencil'
                text
                severity='info'
                size='large'
                onClick={handleEditClick}
              />
            </div>
          </div>
          <div style={{marginTop: '2rem', display: 'flex', justifyContent: 'space-between'}}>
            <div style={{width: '50%'}}>
              <h4>Username and email</h4>
              <p>{user.data?.email}</p>
            </div>
            <div style={{width: '50%'}}>
              <h4>Roles</h4>
              {user.data?.roles?.join(', ')}
              <div>
                <Button
                  label='Manage Roles'
                  text
                  size='small'
                  onClick={handleRoleClick}
                  pt={{root: {style: {margin: 0, padding: 0}}}}
                />
              </div>
            </div>
          </div>

          <div style={{marginTop: '2rem', display: 'flex', justifyContent: 'space-between'}}>
            <div style={{marginTop: '2rem', width: '50%'}}>
              <h4>
                <strong>Contact Information</strong>
              </h4>
              <div style={{marginTop: '1rem'}}>
                <h5>Display name</h5>
                <p>
                  {user.data?.firstName} {user.data?.lastName}
                </p>
                <h4>Phone</h4>
                <p>{user.data?.phone}</p>
              </div>
            </div>
            <div style={{marginTop: '2rem', width: '50%'}}>
              <h4>2 Factor Authentication</h4>
              <InputSwitch checked={!!user.data?.mfaEnabled} onClick={handleMfaClick} />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
