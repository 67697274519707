import {Dialog} from 'primereact/dialog'
import {useCurrentUser} from 'src/queries/getCurrentUser'
import QRCode from 'react-qr-code'
import {useMfaQrCode} from '../../api/getQrCode'
import {useVerifyMfa} from '../../api/verifyMfa'
import {InputOtp, InputOtpChangeEvent} from 'primereact/inputotp'
import {useState} from 'react'
import {Button} from 'primereact/button'

export const SetupMfaPage = () => {
  const user = useCurrentUser()
  const qrCode = useMfaQrCode(!!user.data?.shouldSetupMfa)
  const [otp, setOtp] = useState<number | undefined>()
  const verifyMfa = useVerifyMfa()

  const handleOtpChange = (e: InputOtpChangeEvent) => {
    setOtp(e.value as number | undefined)
  }

  const handleVerifyMfa = () => {
    if (!!otp) verifyMfa.mutate(otp.toString())
  }
  return (
    <div>
      <Dialog
        modal
        visible={user.data?.shouldSetupMfa}
        header='Setup 2 Factor Authentication'
        onHide={() => {}}
        content={() => (
          <div className='flex flex-column gap-4 bg-white rounded p-6'>
            <h2>Your administrator requires you to setup 2 factor authentication</h2>
            <p>Follow these steps to secure your account with 2FA:</p>
            <ol type='1' className='gap-4'>
              <li>
                1. Download the <strong>Microsoft Authenticator</strong> app from the App Store or
                Google Play Store.
              </li>
              <li>
                2. Open the app and tap <strong>Add Account</strong>, then select{' '}
                <strong>Work or school account</strong> or <strong>Other account</strong>.
              </li>
              <li>
                3. Scan the QR code below with the app.
                <div className='mt-5 text-center border-solid p-3 rounded-lg w-full flex justify-center mb-5'>
                  <QRCode value={qrCode.data?.qrCode ?? ''} size={200} />
                </div>
              </li>
              <li>
                4. Enter your generated code below.
                <div className='mt-5 text-center p-3 w-full flex justify-center flex-col gap-4 items-center'>
                  <InputOtp length={6} integerOnly onChange={handleOtpChange} value={otp} />
                  <Button
                    label='Submit'
                    className='w-44'
                    onClick={handleVerifyMfa}
                    loading={verifyMfa.isPending}
                    severity={verifyMfa.isError ? 'danger' : undefined}
                  />
                </div>
              </li>
            </ol>
          </div>
        )}
      ></Dialog>
    </div>
  )
}
