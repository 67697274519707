/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login, mfaLogin} from '../core/_requests'
import {useAuth} from '../core/Auth'
import {Dialog} from 'primereact/dialog'

import {InputOtp, InputOtpChangeEvent} from 'primereact/inputotp'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [showMfaModal, setShowMfaModal] = useState(false)
  const [otp, setOtp] = useState<number>()
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const auth = await login(values.email, values.password)
        if (auth.mfaEnabled && !auth.api_token) {
          setShowMfaModal(true)
          setLoading(false)
          return
        }
        saveAuth(auth)
        const {data: user} = await getUserByToken(auth.api_token)
        setCurrentUser(user)
        navigate('/')
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })
  const handleOtpChange = (e: InputOtpChangeEvent) => {
    const {value} = e
    if (value) setOtp(value as number)
  }

  const handleSubmitMfa = async () => {
    setLoading(true)
    try {
      const auth = await mfaLogin(formik.values.email, otp?.toString() ?? '')
      saveAuth(auth)
      const {data: user} = await getUserByToken(auth.api_token)
      setCurrentUser(user)
      navigate('/')
    } catch (error) {
      console.error(error)
      saveAuth(undefined)
      formik.setStatus('The login details are incorrect')
      formik.setSubmitting(false)
      setLoading(false)
    }
    setShowMfaModal(false)
  }
  return (
    <>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        <div className='text-center mb-11'>
          <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
        </div>

        <div className='separator separator-content my-14'>
          <span className='w-125px text-gray-500 fw-semibold fs-7'>Email</span>
        </div>

        <div className='fv-row mb-8'>
          <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
          <input
            placeholder='Email'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
            type='email'
            name='email'
            autoComplete='off'
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-3'>
          <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
          <input
            type='password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
          <div />

          <Link to='/auth/forgot-password' className='link-primary'>
            Forgot Password ?
          </Link>
        </div>
        <div className='d-grid mb-10'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-primary'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Continue</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        <div className='text-gray-500 text-center fw-semibold fs-6'>
          By logging in and using AgesCareAI users acknowledge and agree to the terms and
          conditions.
        </div>
      </form>
      <Dialog
        onHide={() => setShowMfaModal(false)}
        visible={showMfaModal}
        style={{width: '450px'}}
        header='2 Factor Authentication'
        footer={
          <div>
            <button className='btn btn-primary' onClick={handleSubmitMfa}>
              Submit
            </button>
          </div>
        }
      >
        <div className='flex flex-column align-items-center'>
          <div>Enter Authenticator code</div>
          <InputOtp length={6} onChange={handleOtpChange} value={otp} />
        </div>
      </Dialog>
    </>
  )
}
