import {useMutation} from '@tanstack/react-query'
import {usersClient} from 'src/api/apiClient'
import {AdminToggleUserMfaCommand} from 'src/api/generatedClient'
import {queryClient} from 'src/libs/react-query'

const enableMfa = async (username: string) => {
  const request = new AdminToggleUserMfaCommand({username: username})
  await usersClient.toggleMfa(request)
}

export const useEnableMfa = () =>
  useMutation({
    mutationFn: enableMfa,
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: ['users']})
    },
  })
