import React, {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import ReactQuill, {Quill} from 'react-quill'
import {AssistService} from '../../../../../api/services/AssistService'
import showdown from 'showdown'
import removeHtmlTags from '../../../../common/helpers/removeHtmlTags'
import useLoader from '../../../../common/hooks/useLoader/useLoader'
import styles from './TextEditor.module.scss'
import 'react-quill/dist/quill.snow.css'

interface Props {
  text: string
  fileId?: string
  setText: (text: string) => void
  readonly?: boolean
  textSelected?: (text: string) => void
  textDeselected?: () => void
}

interface Commands {
  insertText?: (text: string) => void
  getSelectedText?: () => string
  insertMarkdown?: (markdown: string) => void
  getSelectedMarkdown?: () => string
}

interface Range {
  index: number
  length: number
}

const icons = Quill.import('ui/icons')
icons['undo'] =
  '<svg viewbox="0 0 18 18"><polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon><path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path></svg>'
icons['redo'] =
  '<svg viewbox="0 0 18 18"><polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon><path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path></svg>'
icons['export'] =
  '<i class="fa fa-download" aria-hidden="true" title="Export document to file"></i>'
icons['copy-to-clipboard'] =
  '<i class="fa fa-clipboard" aria-hidden="true" title="Copy document to clipboard"></i>'

const toolbarOptions = {
  container: [
    ['undo', 'redo'],
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['blockquote', 'code-block'],
    [{header: 1}, {header: 2}], // custom button values
    [{list: 'ordered'}, {list: 'bullet'}],
    [{script: 'sub'}, {script: 'super'}], // superscript/subscript
    [{indent: '-1'}, {indent: '+1'}], // outdent/indent
    [{size: ['small', false, 'large', 'huge']}], // custom dropdown
    [{header: [1, 2, 3, 4, false]}],

    [{color: []}, {background: []}], // dropdown with defaults from theme
    [{font: []}],
    [{align: []}],
    ['clean'], // remove formatting button
    ['export', 'copy-to-clipboard'],
    // ['table'], // Add table button
  ],
  handlers: {
    undo: () => {},
    redo: () => {},
    export: () => {},
    'copy-to-clipboard': () => {},
  },
}

const modules = {
  toolbar: toolbarOptions,
}

const converter = new showdown.Converter({tables: true})

const TextEditor = forwardRef<Commands, Props>((props: Props, ref: ForwardedRef<Commands>) => {
  const {fileId, text, setText, textSelected, textDeselected} = props
  const quillRef = useRef(null)
  const [currentRange, setCurrentRange] = useState<Range>({index: 0, length: 0})
  const {setLoading: setExporting} = useLoader()

  const insertText = (newText: string) => {
    const quill = (quillRef.current as any)?.getEditor()
    if (!quill || !newText) {
      return
    }
    console.log(newText)
    // let quillContent = quill.root.innerHTML
    quill.deleteText(currentRange.index, currentRange.length)
    // // quill.insertText(currentRange.index, newText)
    // const before = quillContent.substring(0, currentRange.index)
    // const after = quillContent.substring(currentRange.index)
    // quillContent = before + newText + after
    // console.log('quillContent', quillContent)
    // const newContent = quill.clipboard.convert(quillContent)
    // console.log('newContent', newContent)

    quill.clipboard.dangerouslyPasteHTML(currentRange.index, newText, 'api')
    const plainText = removeHtmlTags(newText)
    quill.setSelection(currentRange.index, plainText.length)
  }
  const getSelectedText = (): string => {
    const quill = (quillRef.current as any)?.getEditor()
    if (!quill) {
      return ''
    }
    return currentRange.length
      ? quill.getText(currentRange.index, currentRange.length)
      : quill.getText()
  }

  useImperativeHandle(
    ref,
    () => ({
      insertText: insertText,
      getSelectedText: getSelectedText,
      insertMarkdown(markdown: string) {
        const html = converter.makeHtml(markdown)
        insertText(html)
      },
      getSelectedMarkdown: () => {
        const html = getSelectedText()
        return converter.makeMarkdown(html)
      },
    }),
    [currentRange]
  )

  useEffect(() => {
    if (quillRef.current != null) {
      const quill = (quillRef.current as any)?.getEditor()
      quill.on('selection-change', function (range: Range, oldRange: Range, source: string) {
        if (!range) {
          return
        }

        setCurrentRange(range)
        if (range.length > 0) {
          const text = quill.getText(range.index, range.length)
          textSelected?.(text)
        } else {
          textDeselected?.()
        }
      })
      quill.on('text-change', function (delta: any, oldDelta: any, source: any) {
        const selection = quill.getSelection()
        if (source === 'user' && selection) {
          setCurrentRange({index: selection.index + 1, length: selection.length})
        }
      })
    }
  }, [])

  useEffect(() => {
    if (fileId && quillRef.current != null) {
      setTimeout(() => {
        const quill = (quillRef.current as any)?.getEditor()
        const history = quill.getModule('history')
        history.clear() // This will clear the undo and redo stacks
      }, 0)
    }
  }, [fileId])

  const copyToClipboardHandler = useCallback(() => {
    const quill = (quillRef.current as any)?.getEditor()
    const rich = quill.root.innerHTML // Get the HTML content of the editor
    const html = new Blob([rich], {type: 'text/html'})
    const data = new ClipboardItem({'text/html': html})
    navigator.clipboard.write([data]).then(() => {
      alert('Document copied to clipboard')
    })
  }, [])

  const exportHandler = useCallback(() => {
    const quill = (quillRef.current as any)?.getEditor()
    const html = quill.root.innerHTML // Get the HTML content of the editor

    setExporting(true, 'We are preparing your file.')
    AssistService.assistExportHtmlToFile(html)
      .then((response) => {
        return response.blob()
      })
      .then((blob) => {
        // Create a Blob URL
        const url = window.URL.createObjectURL(blob)

        // Create a link element
        const link = document.createElement('a')

        // Set the link's href to the Blob URL
        link.href = url

        // Set the download attribute to the desired file name
        link.download = 'document.docx'

        // Simulate a click on the link
        link.click()
        setExporting(false)
      })
      .catch((error) => {
        console.error('Error:', error)
        setExporting(false)
      })

    // // Create a Blob from the HTML
    // const blob = new Blob([html], {
    //   type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    // })
    //
    // // Create a link element
    // const link = document.createElement('a')
    //
    // // Set the link's href to a URL created from the Blob
    // link.href = URL.createObjectURL(blob)
    //
    // // Set the download attribute to the desired file name
    // link.download = 'document.docx'
    //
    // // Append the link to the body
    // document.body.appendChild(link)
    //
    // // Simulate a click on the link
    // link.click()
    //
    // // Remove the link from the body
    // document.body.removeChild(link)
  }, [setExporting])

  useEffect(() => {
    if (!quillRef.current) {
      return
    }

    const quill = (quillRef.current as any)?.getEditor()

    // Define the custom handlers
    const undoHandler = () => {
      const history = quill.getModule('history')
      history.undo()
    }
    const redoHandler = () => {
      const history = quill.getModule('history')
      history.redo()
    }

    // Add the custom handlers to the toolbar module
    quill.getModule('toolbar').addHandler('undo', undoHandler)
    quill.getModule('toolbar').addHandler('redo', redoHandler)
    quill.getModule('toolbar').addHandler('export', exportHandler)
    quill.getModule('toolbar').addHandler('copy-to-clipboard', copyToClipboardHandler)
  }, [quillRef, exportHandler, copyToClipboardHandler])

  return (
    <ReactQuill
      ref={quillRef}
      value={text}
      theme='snow'
      readOnly={props.readonly}
      className={styles.textEditor}
      modules={modules}
      onChange={(newText) => {
        console.log({text, newText})
        setText(newText)
      }}
    />
  )
})

export type {Commands}
export default TextEditor
