import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {locale} from 'devextreme/localization'
import {GoogleAnalyticsTag} from './components/googleAnalytics'
import LoaderProvider from './common/hooks/useLoader/LoaderProvider'
import {registerLicense} from '@syncfusion/ej2-base'
import {SetupMfaPage} from './pages/Auth'

registerLicense(
  'Ngo9BigBOggjHTQxAR8/V1NDaF5cWWtCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWH9fdHRQRWJeWEN1WUQ='
)
const App = () => {
  locale('en-AU')
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LoaderProvider>
          <LayoutProvider>
            <GoogleAnalyticsTag />
            <AuthInit>
              <Outlet />
              <SetupMfaPage />
              <MasterInit />
            </AuthInit>
          </LayoutProvider>
        </LoaderProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
