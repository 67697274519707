import user from 'src/app/pages/AskMe/mocks/user'
import {axiosMiddleware, lexiApi} from './axiosMiddleware'
import {
  AccountClient,
  AppConfigurationClient,
  AskMeRolesClient,
  AuthClient,
  FilesClient,
  IAccountClient,
  IAppConfigurationClient,
  IAskMeRolesClient,
  IAuthClient,
  IFilesClient,
  IMfaClient,
  IPythiaClient,
  IUsersClient,
  MfaClient,
  PythiaClient,
  UsersClient,
} from './generatedClient'
const baseUrl = ''

export const pythiaClient: IPythiaClient = new PythiaClient(baseUrl, axiosMiddleware)
export const accountClient: IAccountClient = new AccountClient(baseUrl, axiosMiddleware)
export const filesClient: IFilesClient = new FilesClient(baseUrl, axiosMiddleware)
export const askMeRolesClient: IAskMeRolesClient = new AskMeRolesClient(baseUrl, axiosMiddleware)
export const appConfigurationClient: IAppConfigurationClient = new AppConfigurationClient(
  baseUrl,
  axiosMiddleware
)
export const usersClient: IUsersClient = new UsersClient(baseUrl, axiosMiddleware)
export const authClient: IAuthClient = new AuthClient(baseUrl, axiosMiddleware)
export const mfaClient: IMfaClient = new MfaClient(baseUrl, axiosMiddleware)
